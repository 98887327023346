import { Auth0Client } from '@auth0/auth0-spa-js';

export const auth0Client = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  // useRefreshTokens: true,
  cacheLocation: 'memory',
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECTURI,
    scope: process.env.REACT_APP_AUTH0_SCOPE,
  },
  onRedirectCallback: (appState) => {
    if (appState && appState.returnTo) {
      window.history.replaceState(
        {},
        document.title,
        appState.returnTo
      );
    }
  }
});

export const auth0SecondaryClient = new Auth0Client({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_SECONDARY_CLIENTID,
  // useRefreshTokens: true,
  cacheLocation: 'memory',
  authorizationParams: {
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECTURI,
    scope: process.env.REACT_APP_AUTH0_SCOPE,
  },
  onRedirectCallback: (appState) => {
    if (appState && appState.returnTo) {
      window.history.replaceState(
        {},
        document.title,
        appState.returnTo
      );
    }
  }
});

export function handleAuthentication() {
  return new Promise((resolve, reject) => {
    auth0Client.handleRedirectCallback().then(async () => {
      const user = await auth0Client.getUser();
      sessionStorage.setItem("user", JSON.stringify(user));
      resolve(user);
    }).catch(err => {
      reject(err);
    });
  });
}

export function signIn() {
  auth0Client.loginWithRedirect({
    appState: {
      target: window.location.pathname,
      returnTo: window.location.pathname
    }
  });
}

export function signOut() {
  sessionStorage.clear();
  auth0Client.logout({
    returnTo: process.env.REACT_APP_AUTH0_LOGOUTURI,
    client_id: process.env.REACT_APP_AUTH0_CLIENTID,
  });
}

const getToken = async () => {
  const accessToken = await auth0Client.getTokenSilently();
  return accessToken;
}
export { getToken };

const getSecondaryToken = async () => {
  const accessToken = await auth0SecondaryClient.getTokenSilently();
  return accessToken;
}
export { getSecondaryToken };

const fetchProtectedData = async () => {
  const isAuthenticated = await auth0Client.isAuthenticated();
  if (isAuthenticated) {
    try {
      const accessToken = await auth0Client.getTokenSilently();

      // Use the access token to make an authenticated API call
      const response = await fetch('https://dev-atcsbcounty2.us.auth0.com/userinfo', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      if (error.error === 'login_required') {
        // The user's session has expired, prompt them to log in again
        await auth0Client.loginWithRedirect();
      }
    }
  } else {
    // The user is not authenticated, prompt them to log in
    await auth0Client.loginWithRedirect();
  }
};

export { fetchProtectedData };

const handleLogin = async () => {
  await auth0Client.loginWithRedirect({
    redirect_uri: process.env.REACT_APP_AUTH0_REDIRECTURI,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: process.env.REACT_APP_AUTH0_SCOPE,
    appState: {
      target: window.location.pathname,
      returnTo: window.location.pathname
    }
  });
};


const getIsAuthenticated = async () => {
  const checkSessionStorageItem = async () => {
    const user = sessionStorage.getItem("user");
    return !!user;
  };
  return await checkSessionStorageItem();
}
export { handleLogin, getIsAuthenticated };