import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const ExcessProceeds = () => {
    const [excessProceeds, setExcessProceeds] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("webpage/excessProceeds");
        setExcessProceeds(result);
    };

    return (
        excessProceeds && (
            <StaticPage title={excessProceeds.pageTitle}>
                {parseHtml(excessProceeds.content)}
            </StaticPage>
        )
    );
};

export default ExcessProceeds;