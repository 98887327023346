import AboutDivision from "../../components/about-divisions/about-division";
import PropTypes from "prop-types";

function PropertyTaxAbout({ division }) {
  return (
    <AboutDivision divisionAbbreviation={division.divisionAbbreviation} linkToDivisionHome="/property-tax" />
  );
}

PropertyTaxAbout.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default PropertyTaxAbout;
