import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";

function InformationalTiles({ title, icon, content, secondaryIcon, secondaryContent, side }) {
  const isScreenSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isScreenXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const primaryIconSizeBig = 185;
  const primaryIconSizeSmall = isScreenXs ? 70 : isScreenSmall ? 80 : 70;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: side === "left" ? "row" : "row-reverse",
        paddingBottom: isScreenSmall ? "65px" : "100px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          paddingRight: !isScreenSmall && side === "left" ? "60px" : 0,
          paddingLeft: !isScreenSmall && side === "right" ? "60px" : 0,
        }}
      >
        <div className="d-flex flex-row align-items-center">
          {isScreenSmall && (
            <Box
              sx={{
                minWidth: primaryIconSizeSmall,
                maxWidth: primaryIconSizeSmall,
                textAlign: "center",
                marginRight: "16px",
              }}
            >
              <img src={icon} alt={title} style={{ width: "100%", height: "auto" }} />
            </Box>
          )}
          <h2>{title}</h2>
        </div>
        <div className="py-2 py-xl-0">{content}</div>
        {secondaryContent && (
          <div className="d-flex flex-wrap column-gap-5 row-gap-2 mb-3">
            {secondaryContent.map((item, i) => (
              <div key={i} className="d-flex gap-2 align-items-center">
                <img src={secondaryIcon} alt={title} width={20} height={20} />
                {item}
              </div>
            ))}
          </div>
        )}
      </Box>

      {!isScreenSmall && (
        <Box sx={{ minWidth: primaryIconSizeBig, maxWidth: primaryIconSizeBig, textAlign: "center" }}>
          <img src={icon} alt={title} style={{ width: "100%", height: "auto" }} />
        </Box>
      )}
    </Box>
  );
}


InformationalTiles.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  secondaryIcon: PropTypes.string,
  secondaryContent: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  side: PropTypes.oneOf(["left", "right"]),
};

export default InformationalTiles;