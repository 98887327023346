import React from 'react'
import CookieConsent from 'react-cookie-consent'
import './cookie-consent.scss'
import CustomCookieButton from './custom-cookie-button';
import { useGAContext } from '../../_contexts/ga-context/ga-context';
import { addGAScript } from '../../utils/analytics';

const CookieConsentContainer = () => {
    const { updateConsentGiven } = useGAContext();

    const handleAccept = () => {
        // Add the Google Analytics script only in production or specific environments
        if (process.env.REACT_APP_ENV === 'production' || process.env.REACT_APP_ENV === 'qa') {
            addGAScript();
        }
        updateConsentGiven(true);
    }

    const handleDecline = () => {
        updateConsentGiven(false);
    }

    return (
        <div>
            <CookieConsent
                location='bottom'
                buttonText='Allow'
                cookieName='GATrackingConsent'
                style={{ background: 'white', minHeight: '15vh', zIndex: '1999999', boxShadow: '0px 0px 10px rgba(0,0,0,0.5)', padding: '1rem' }}
                disableButtonStyles={true}
                expires={150}
                enableDeclineButton={true}
                declineButtonText='Decline'
                contentClasses='cookie-consent-content'
                ButtonComponent={CustomCookieButton}
                onAccept={handleAccept}
                onDecline={handleDecline}
            >
                <p>This government website uses cookies to enhance the user experience and provide anonymized site usage analytics.
                    By accepting, you agree to the use of anonymized Google Analytics for monitoring site performance.
                </p>
                <p>You can change your preferences at any time in the  <a href="/privacy-settings">Privacy Settings.</a></p>
            </CookieConsent>
        </div>
    )
}

export default CookieConsentContainer