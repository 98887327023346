import React from 'react'
import DefaultLayout from '../../components/layouts/default-layout';
import GSGWidget from '../../components/gsg-widget/gsg-widget';
import { useLocation } from 'react-router';
import { getWidgetConfig } from '../../utils/gsg-dynamic-config';

function DirectCharges() {
    const location = useLocation();
    const config = location.state ? location.state.config : getWidgetConfig('direct-charges').widget;

    return (<DefaultLayout title="Direct Charges" >
        <div>
            <div style={{ textAlign: 'center' }}>To gain access to view direct charges, please log into your public portal account and submits a request for access here.</div>
            <div style={{ textAlign: 'center', color: 'red' }}>The GSG widget displayed below is for example only. It will be changed to direct-charges widget when available.</div>
            <GSGWidget initialPath={config.initialPath} requireAuthentication={config.requireAuthentication} />
        </div>
    </DefaultLayout>);
}

export default DirectCharges;
