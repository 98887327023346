import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const SuccessorAgencyDissolutionInformation = () => {
  const [successorAgencyDissolutionInformation, setSuccessorAgencyDissolutionInformation] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/successorAgencyDissolutionInfo");
    setSuccessorAgencyDissolutionInformation(result);
  };

  return (
    successorAgencyDissolutionInformation && (
      <StaticPage title={successorAgencyDissolutionInformation.pageTitle}>
        {parseHtml(successorAgencyDissolutionInformation.content)}
      </StaticPage>
    )
  );
};

export default SuccessorAgencyDissolutionInformation;
