import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import "./news-ticker.scss";

export default function NewsTickerComponent() {

    return (

        <Box className="" alignItems="center" sx={{ background: "white" }} >
            <Grid container >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="news-ticker" >
                        <iframe title="treasurer-news-ticker" width="100%" height="60" src="https://rss.app/embed/v1/ticker/Ql4zBaXryiotHrJR" frameBorder="0"></iframe>
                    </div>
                </Grid>
            </Grid>
        </Box>

    );
}

