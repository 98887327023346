import React from 'react';

function Loader() {
    return (
        <div className='d-flex w-100 justify-content-center my-4'>
            <div className="spinner-grow text-secondary spinner-grow-sm m-1" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-secondary spinner-grow-sm m-1" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-secondary spinner-grow-sm m-1" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

export default Loader;