import { Button, Card } from "@mui/material";
import { parseHtml } from "../../utils/utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ExecMemberCard = ({ member }) => {
  return (
    <Card className="exec-card shadow-lg">
      <div className="exec-card-info">
        <img className="mb-3" src={member.imageUrl} width="200" alt="team member" />
        <div className="d-flex flex-column p-0 m-0">
          <b>{`${member.firstName} ${member.lastName} ${member.suffix ? member.suffix : ""}`}</b>
          <small>{member.position}</small>
        </div>
      </div>

      <div className="exec-card-desc">
        <div className="d-flex align-items-center column-gap-3 pb-3">
          <img src={member.imageUrl} width="65" alt="team member" />
          <div className="d-flex flex-column p-0 m-0">
            <b>{`${member.firstName} ${member.lastName} ${member.suffix ? member.suffix : ""}`}</b>
            <p style={{ fontSize: "0.85em", margin: 0 }}>{member.position}</p>
            {member.division !== "Administration" && member.divisionLink && (
              <Button
                size="small"
                variant="outlined"
                sx={{
                  width: "fit-content",
                  padding: "0 8px",
                  marginTop: "4px",
                  borderRadius: "15px",
                  textTransform: "none",
                }}
                component={Link}
                to={member.divisionLink}
              >
                ⮞ Visit division page
              </Button>
            )}
          </div>
        </div>

        <small>{parseHtml(member.biography)}</small>
      </div>
    </Card>
  );
};

ExecMemberCard.propTypes = {
  member: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    position: PropTypes.string.isRequired,
    biography: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    division: PropTypes.string,
    divisionLink: PropTypes.string,
  }).isRequired,
};

export default ExecMemberCard;
