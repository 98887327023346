import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";

const LinkActionCardSub = ({ title, linkTitle, handleClick, children, icon }) => {
  const handleAction = () => {
    handleClick();
  };
  return (
    <Card
      className={`shadow-lg w-100 h-100 link-action-card dark`}
      style={{ backgroundColor: "transparent" }}
    >
      <CardActionArea
        onClick={handleAction}
        aria-label={linkTitle}
        sx={{
          display: { xs: "flex", md: "block" },
          flexDirection: { xs: "column", md: "none" },
          height: { xs: "calc(210px + 3vw)", sm: "230px", lg: "270px" },
          border: "1.5px solid white",
          borderRadius: 2,
          transition: "background-color 0.25s linear",
          ":hover": { backgroundColor: "rgba(255, 255, 255, 0.1)" },
        }}
      >
        <CardContent
          className="d-flex flex-column justify-content-center align-items-center text-center"
          sx={{ padding: { xs: "1rem", xl: "min(1.5vw, 3rem)" } }}
        >
          {icon}
          <div className="card-title m-0" style={{ fontSize: "1.75rem", textTransform: "uppercase" }}>
            {title}
          </div>
          <div className="card-body">{children}</div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

LinkActionCardSub.propTypes = {
  title: PropTypes.string.isRequired,
  linkTitle: PropTypes.string,
  linkDestination: PropTypes.string,
  handleClick: PropTypes.func,
  icon: PropTypes.node,
  children: PropTypes.node,
};

export default LinkActionCardSub;
