import 'devextreme/dist/css/dx.light.css';
import "./styles/styles.scss";
import { ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Layout from "./components/layouts/main";
import AppRoutes from "./_routes/app-routes";
import ScrollToTop from "./components/scroll-to-top";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useMeta from './hooks/useMeta';
import CookieConsentContainer from "./components/cookie-consent/cookie-consent-container";
import { GAContextProvider } from "./_contexts/ga-context/ga-context";
import config from 'devextreme/core/config';

config({
    licenseKey: process.env.REACT_APP_DEVEXTREME_LICENSE_KEY
});




const theme = createTheme({
    palette: {
        primary: {
            main: "#003366",
        },
        secondary: {
            main: "#cd9700",
        },
    },
});



function App() {
    const meta = useMeta();
    return (
        <>
            <GAContextProvider>
                <CookieConsentContainer />
                <HelmetProvider>
                    <ThemeProvider theme={theme}>
                        <div className="App">
                            <Helmet>
                                <title>{meta.title}</title>
                                <meta name="description" content={meta.description} />
                            </Helmet>
                            <ParallaxProvider>
                                <Layout>
                                    <Routes>
                                        {AppRoutes.map((route) => (
                                            <Route
                                                path={route.path}
                                                element={<ScrollToTop>{route.component}</ScrollToTop>}
                                                key={route.path}
                                            />
                                        ))}
                                    </Routes>
                                </Layout>
                            </ParallaxProvider>
                        </div>
                    </ThemeProvider>
                </HelmetProvider>
            </GAContextProvider>
        </>
    );
}

export default App;
