import { useLocation } from 'react-router-dom';
import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    SearchBox,
    Hits,
    RefinementList,
    Pagination,
    Configure,
    DynamicWidgets,

} from 'react-instantsearch';
import 'instantsearch.css/themes/satellite.css';
import { parseHtml } from "../../utils/utils";
import "./division-service-search-result.scss";
import PropTypes from "prop-types";

const appid = process.env.REACT_APP_ALGOLIA_APP_ID;
const searchApiKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const indexName = process.env.REACT_APP_ALGOLIA_SERVICES_INDEX;
const searchClient = algoliasearch(appid, searchApiKey);


function Hit({ hit }) {
    return (
        <article>
            <a href={hit.link}>
                {hit.serviceLabel}
                {/* <Highlight attribute="serviceLabel" hit={hit}  />*/}
            </a>
            {
                parseHtml(hit.serviceDescription)
            }
        </article>
    );
}
function DivisionServiceSearchResult() {
    //const { query } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query');

    return (
        <div className="division-service-search-result container">
            <InstantSearch
                searchClient={searchClient}
                indexName={indexName}
                initialUiState={
                    {
                        [indexName]: {
                            query: query
                        },
                    }
                }
                routing={true}
            >
                <Configure hitsPerPage={10} />

                <div className="SearchContainer">
                    <div>
                        <div className="ais-Panel-header">Divisions</div>
                        <DynamicWidgets>

                            <RefinementList
                                attribute="divisionName"

                            //searchable={true}
                            //searchablePlaceholder="Search division"
                            //showMore={true}
                            />
                        </DynamicWidgets>
                    </div>
                    <div className="Search">
                        <SearchBox placeholder="Search" autoFocus={true} />
                        <Hits hitComponent={Hit} />
                        <Pagination />
                    </div>
                </div>
            </InstantSearch>
        </div>
    );
}

Hit.propTypes = {
    hit: PropTypes.object.isRequired,
};

DivisionServiceSearchResult.propTypes = {
    query: PropTypes.string,
};

export default DivisionServiceSearchResult;

