import React from "react";
import { Card, CardActions, CardContent, CardMedia, Chip, Grid, Typography } from "@mui/material";
import { useHits } from "react-instantsearch";
import ViewFileButtons from "./view-file-buttons";

function CustomCardHits(props) {
  const { results } = useHits(props);
  if (!results) return null;
  const { hits } = results;

  return (
    <>
      <Grid container spacing={3}>
        {hits.map((hit, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Card className="d-flex justify-content-between h-100">
              <CardMedia
                component="img"
                sx={{ width: 160 }}
                image="/assets/images/atc5.jpg"
                alt={hit.report_name}
              />

              <CardContent sx={{ width: "100%", padding: "18px 20px" }}>
                <Typography variant="body">{hit.report_name}</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {hit.report_category}
                </Typography>
                <div className="pt-1">
                  <Chip label={hit.division} size="small" />
                </div>
              </CardContent>

              <CardActions className="me-3">
                <ViewFileButtons hit={hit} />
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default CustomCardHits;
