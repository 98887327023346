import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { govHubSubPath } from '../../utils/utils.js';

function ShoppingCart() {
    const navigate = useNavigate();
    const handleClick = () => {
        return navigate("/" + govHubSubPath + "/checkout");
    }
    return (
        <IconButton color="secondary" aria-label="add to shopping cart" onClick={handleClick}>
            <FontAwesomeIcon icon={faShoppingCart} />
        </IconButton>

    );
}

export default ShoppingCart;