import resolveResponse from "contentful-resolve-response";
import api from "./config";
const spaceID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;
const url = process.env.REACT_APP_CMS_API_URL

export const getEntriesByContentType = async (contentType) => {
  try {
    const response = await api.get(
      `/cms-delivery/spaces/${spaceID}/environments/${environment}/entries?content_type=${contentType}`
    );
    const result = resolveResponse(await response.data);
    return result;
  } catch (error) {
    console.error("Error fetching Contentful data:", error);
    return null;
  }
};

export const getDataFromCmsApi = async (apiParameter) => {
  try {
    const response = await api.get(`${url}/${apiParameter}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching Contentful data from CMS API:", error);
    return null;
  }
};
