import React, { useEffect, useState } from "react";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";

function InvestmentPool() {
  const [data, setData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/managementInvestmentPool");
    setData(result);
  };

  return data && <StaticPageContainer title={data.pageTitle}>{parseHtml(data.content)}</StaticPageContainer>;
}

export default InvestmentPool;
