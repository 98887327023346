import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const PropertyTaxPostponement = () => {
    const [propertyTaxPostponement, setpropertyTaxPostponement] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("webpage/propertyTaxPostponement");
        setpropertyTaxPostponement(result);
    };

    return (
        propertyTaxPostponement && (
            <StaticPage title={propertyTaxPostponement.pageTitle}>                
                {parseHtml(propertyTaxPostponement.content)}
            </StaticPage>
        )
    );
};

export default PropertyTaxPostponement;
