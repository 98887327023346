import api from './config';

const url = process.env.REACT_APP_FUNCTION_APP_SERVICE_URL;
export const getPSTDate = async () => {

    try {
        const response = await api.get(
            `${url}/GetPSTDate`
        );
        const result = await response.data;
        return result;
    } catch (error) {
        console.error("Error fetching PST Timestamp:", error);
        return null;
    }
};

export const fetchPowerBIEmbed = async () => {
    try {
        const response = await api.get(
            `${url}/GetEmbeddedPowerBI`
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}
