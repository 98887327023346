import React from "react";
import Dashboard from "../../components/treasurer/dashboard";
function InvestmentDashboards() {
  return (
    <>
      <div className="container treasurer mt-md-5">
        <Dashboard />
      </div>
    </>
  );
}

export default InvestmentDashboards;
