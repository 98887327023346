import axios from 'axios';

const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_API_GATEWAY_SUBSCRIPTION_KEY
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_GATEWAY_URL,
    headers
});

export default axiosInstance;