import { useLocation } from 'react-router-dom';
import routes from '../_routes/app-routes.js'; 

const useMeta = () => {
    const location = useLocation();
    const currentRoute = routes.find(route => route.path === location.pathname);

    return currentRoute ? currentRoute.meta : {};
};

export default useMeta;