import React from "react";
import DivisionContact from "../../components/division-homepage/division-contact";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionServices from "../../components/division-homepage/division-services";
import AlertService from "../../components/alert-service/alert-service";
import PropTypes from "prop-types";

function RVRHome({ division }) {
  return (
    <>
      <div className="container">
        <AlertService divisionCode={division.divisionAbbreviation} />
        <DivisionServices divisionAbbreviation={division.divisionAbbreviation} sectionTitle="Our Services" />
      </div>
      <div>
        <DivisionAboutBanner
          divisionAbbreviation={division.divisionAbbreviation}
          divisionTag={division.tag}
          linkTo="/revenue-recovery/about"
        />
        <DivisionContact
          divisionAbbreviation={division.divisionAbbreviation}
          sectionTitle={"Contact Revenue Recovery"}
        />
      </div>
    </>
  );
}

RVRHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default RVRHome;
