import React, { useEffect } from 'react';
import Banner from '../../components/home/banner';
import ImportantLinks from '../../components/home/important-links';
import NewsAndPublications from '../../components/news-publications/news-and-publications';
import MeetYourATC from '../../components/home/meet-your-atc';
import ByTheNumbers from '../../components/home/by-the-numbers';
import Accomplishments from '../../components/home/achievements';
import { GovDelivery } from '../../components/govdelivery/govdelivery.js'

function Home() {

    useEffect(() => {
        ['scroll', 'load'].forEach(function (e) {
            //  window.addEventListener(e, mouseMoveHandler, false);
            window.addEventListener(e, function () {
                var links = document.getElementsByClassName('dot');
                var top = window.scrollY;
                document.querySelectorAll('.sec').forEach(function (value) {
                    var id = value.getAttribute('id');
                    var height = value.scrollHeight;
                    var offset = value.offsetTop - 150;
                    let i = 0;
                    while (i < links.length) {
                        var link = links[i];
                        if (link.getAttribute('data-scroll').toUpperCase() === id.toUpperCase()) {
                            link.classList.remove('active');
                            break;
                        }
                        i++;
                    };
                    if (top >= offset && top < offset + height) {
                        document.querySelector('.ScrollSpyMenu').querySelector('[data-scroll="' + id + '"]').classList.add('active');
                    }
                });
            });
        });

    });

    return (
        <div className='overflow-hidden'>
            <nav className="ScrollSpyMenu d-none d-md-block">
                <ul>
                    <li>
                        <a href="#home" className="dot" data-scroll="welcome">
                            <span>Welcome</span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="#services" className="dot" data-scroll="services">
                            <span>Services</span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="#meetATC" className="dot" data-scroll="meetATC">
                            <span>Meet ATC</span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="#accomplishments" className="dot" data-scroll="accomplishments">
                            <span>Stats</span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="#achievements" className="dot" data-scroll="achievements">
                            <span>Achievements</span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="#news" className="dot" data-scroll="news">
                            <span>News</span>
                        </a>
                    </li>
                </ul>
                <ul>
                    <li>
                        <a href="#stayconnected" className="dot" data-scroll="stayconnected">
                            <span>Stay Connected</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <div className="sec" id="welcome"><Banner></Banner></div>
            <div className="sec" id="services"><ImportantLinks></ImportantLinks></div>
            <div className="sec" id="meetATC"><MeetYourATC></MeetYourATC></div>
            <div className="sec" id="accomplishments"><ByTheNumbers></ByTheNumbers></div>
            <div className="sec" id="achievements"><Accomplishments></Accomplishments></div>
            <div className="sec" id="news"><NewsAndPublications></NewsAndPublications></div>
            <div className="sec" id="stayconnected"><GovDelivery></GovDelivery></div>
        </div>
    )

}

export default Home;