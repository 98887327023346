import TaxCollectorActionCard from "../../components/action-card/link-action-card";

const TaxSale = () => {

    return (
        <>
            <div className="container">
                <div className="grid-container grid-style-4 py-5">
                    <TaxCollectorActionCard
                        key="1"
                        title="Legal Publications"
                        linkDestination="/tax-collector/tax-sale/legal-publications"
                        cardColor="#B5552C"
                    />
                    <TaxCollectorActionCard
                        key="2"
                        title="Auction Site"
                        linkDestination="https://sbcounty.mytaxsale.com/"
                        cardColor="#0A294D"
                        target="_new"
                    />
                    <TaxCollectorActionCard
                        key="3"
                        title="Terms and Conditions"
                        linkDestination="/tax-collector/tax-sale/terms-and-conditions"
                        cardColor="#518466"
                    />
                    <TaxCollectorActionCard
                        key="4"
                        title="Excess Proceeds"
                        linkDestination="/tax-collector/tax-sale/excess-proceeds"
                        cardColor="#1C6889"
                    />
                </div>
            </div>
        </>
    );
};
export default TaxSale;