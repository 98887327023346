import { Button } from "@mui/material";
import React from "react";
import { useClearRefinements } from "react-instantsearch-core";
import PropTypes from "prop-types";

const CustomClearFilterButton = ({ textColor, backgroundColor }) => {
    const { refine, canRefine } = useClearRefinements();

    return (
        <Button
            onClick={refine}
            disabled={!canRefine}
            sx={{
                color: textColor || "",
                "&:hover": {
                    backgroundColor: backgroundColor || "",
                },
            }}
        >
            Clear Filters
        </Button>
    );
};

CustomClearFilterButton.propTypes = {
    textColor: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default CustomClearFilterButton;