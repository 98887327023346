import AboutDivision from "../../components/about-divisions/about-division";
import PropTypes from "prop-types";

function RevenueRecoveryAbout({ division }) {
  return (
    <AboutDivision
      divisionAbbreviation={division.divisionAbbreviation}
      linkToDivisionHome="/revenue-recovery"
    />
  );
}

RevenueRecoveryAbout.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default RevenueRecoveryAbout;
