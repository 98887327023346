import React, { useEffect, useState } from "react";
import InformationalTiles from "../../components/informational-tiles";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";

const LegallyMandatedDuties = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/legallyMandatedDuties");
    setData(result);
  };

  return (
    data && (
      <StaticPageContainer title={data.pageTitle}>
        {data.webpageItems &&
          data.webpageItems.map((item, i) => (
            <InformationalTiles
              key={i}
              title={item.title}
              icon={item.imageUrl}
              content={parseHtml(item.description)}
              side={i % 2 === 0 ? "left" : "right"}
            />
          ))}
      </StaticPageContainer>
    )
  );
};

export default LegallyMandatedDuties;
