import { connect } from "react-redux";
import LoginButton from "../components/login-button/login-button.js";

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user,
    isDrawer: ownProps.isDrawer,
    handleDrawerClose: ownProps.handleDrawerClose,
  };
};

const LoginButtonContainer = connect(mapStateToProps)(LoginButton);

export default LoginButtonContainer;
