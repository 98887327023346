import AboutDivision from "../../components/about-divisions/about-division";
import PropTypes from "prop-types";

function TaxCollectorAbout({ division }) {
  return (
    <AboutDivision divisionAbbreviation={division.divisionAbbreviation} linkToDivisionHome="/tax-collector" />
  );
}

TaxCollectorAbout.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default TaxCollectorAbout;
