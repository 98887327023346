import React from 'react';
import PropTypes from 'prop-types';
import SlotCounter from 'react-slot-counter';

function ByTheNumberItem({ isCurrency = false, number, unit, description }) {
    const curr = `${isCurrency ? '$' : ""}`
    const value = [<span key="currency">{curr}</span>, <span key="number">{number}{' '}</span>, <span key="unit">{unit}</span>];

    return (
        <div className="container text-center">
            <div className='d-flex justify-content-center align-content-center milestone-font mb-2'>
                <SlotCounter
                    animateOnVisible={{ triggerOnce: true }}
                    charClassName=" milestone-font"
                    value={value}
                    duration={3}
                ></SlotCounter>
            </div>
            <div className="milestone-font description text-capitalize">{description}</div>
        </div >
    );
}

ByTheNumberItem.propTypes = {
    isCurrency: PropTypes.bool,
    number: PropTypes.number.isRequired,
    unit: PropTypes.string,
    description: PropTypes.string.isRequired
};

export default ByTheNumberItem;