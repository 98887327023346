import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { FaLink } from "react-icons/fa";

const CopyHashLinkButton = ({ elementId, iconSize }) => {
  const [isTypeLinkCopied, setIsTypeLinkCopied] = useState(false);

  return (
    <Tooltip
      title={isTypeLinkCopied ? "Link copied!" : "Copy link to this section"}
      placement="right"
      disableInteractive
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
      onMouseLeave={() => {
        setTimeout(() => {
          setIsTypeLinkCopied(false);
        }, 180);
      }}
    >
      <IconButton
        onClick={() => {
          setIsTypeLinkCopied(true);
          const url = `${window.location.origin}${window.location.pathname}#${elementId}`;
          navigator.clipboard.writeText(url);
        }}
      >
        <FaLink color="darkgray" size={iconSize} />
      </IconButton>
    </Tooltip>
  );
};

CopyHashLinkButton.propTypes = {
  elementId: PropTypes.string,
  iconSize: PropTypes.number,
};

export default CopyHashLinkButton;
