import React from "react";
import { useCurrentRefinements } from "react-instantsearch-core";
import PropTypes from "prop-types";

const CustomFilterNumber = ({ isFilterOpen, textColor, ...props }) => {
    const { items } = useCurrentRefinements(props);

    if (!items.length) return null;

    const filterCount = items.reduce((acc, item) => acc + item.refinements.length, 0);
    return filterCount === 0 ? null : (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: isFilterOpen ? textColor ? textColor : "black" : "white",
                backgroundColor: !isFilterOpen ? textColor ? textColor : "black" : "white",
                borderRadius: "50%",
                fontWeight: "bold",
                fontSize: "13px",
                width: "19px",
                height: "19px",
                margin: "0 1px",
            }}
        >
            {filterCount}
        </div>
    );
};

CustomFilterNumber.propTypes = {
    isFilterOpen: PropTypes.bool.isRequired,
    textColor: PropTypes.string,
};

export default CustomFilterNumber;