import React from 'react';
import PropTypes from 'prop-types';

function DivisionServiceItem({ hit, components }) {
    //const serviceDescription = parseHtml(hit.serviceDescription);
    return (

        <a href={hit.link} className="aa-ItemLink">
            <div className="aa-ItemWrapper">
                <div className="aa-ItemContent">
                    <div className="aa-ItemContentBody">
                        <div className="aa-ItemContentTitle">
                            {hit.serviceLabel}
                            {/* <components.Highlight hit={hit} attribute="serviceLabel" /> */}
                        </div>
                        {/*<div className="aa-ItemContentDescription">*/}
                        {/*    { serviceDescription}*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </a>

    );
}

DivisionServiceItem.propTypes = {
    hit: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired
};
export default DivisionServiceItem;