import { useEffect } from "react";


const GTranslate = () => {
    // Initialize GTranslate Translate
    const gTranslateElementInit = () => {
        new window.g.translate.TranslateElement(
            {
                default_language: "en",
                detect_browser_language: true,
                wrapper_selector: ".gtranslate_wrapper",
                horizontal_position: "right",
                vertical_position: "top"
            },
            "g_translate_element"
        );
    };





    useEffect(() => {
        // Check if the script is already added
        const existingScript = document.getElementById("g-translate-script");
        if (!existingScript) {
            const script = document.createElement("script");
            script.id = "g-translate-script";
            script.src = "//cdn.gtranslate.net/widgets/latest/dropdown.js";
            script.async = true; // Ensure the script loads asynchronously
            document.body.appendChild(script);
            // Make sure to define the callback function
            window.gTranslateElementInit = gTranslateElementInit;
            //    document.getElementById('g_translate_element').addEventListener('change', handleLanguageDropdownChange);
            //handleLanguageDropdownChange();
        }
    }, []);

    return (
        <>
            <div id="g_translate_element" className="gtranslate_wrapper" style={{ marginTop: '5px' }}></div>
        </>
    );
};

export { GTranslate };