import PropTypes from "prop-types";
import DefaultLayout from "./default-layout";

function DivisionHomepageLayout({ division, children }) {
  return (
    <DefaultLayout description={"Welcome to San Bernardino County's"} title={division.title} divisionTag={division.tag}>
      {children}
    </DefaultLayout>
  );
}

DivisionHomepageLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  division: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default DivisionHomepageLayout;
