import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const ManagementServices = () => {
  const [managementServices, setManagementServices] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/managementServices");
    setManagementServices(result);
  };

  return (
    managementServices && (
      <StaticPage title={managementServices.pageTitle}>
        {parseHtml(managementServices.content)}
      </StaticPage>
    )
  );
};

export default ManagementServices;
