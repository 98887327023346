import { useEffect, useState } from "react";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";

const pageTitle = "Legal Publications";
const LegalPublications = () => {
    const [data, setPublications] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("NewsAndPublications");
        const allPublications = result.filter((item) => {
            return item.category === "Publication";
        });

        setPublications(allPublications);
    };
    return (
        <StaticPageContainer title={pageTitle}>
            <ol>
                {data.map((item, i) => (
                    <li key={i}>
                        <div className="container">
                            {parseHtml(item.description)}
                            <p><a href={parseHtml(item.documentUrl)} target="_new">{parseHtml(item.documentTitle)}</a></p>
                        </div>
                    </li>
                ))}
            </ol>
        </StaticPageContainer>
    )
}
export default LegalPublications;