import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  Grid,
} from "@mui/material";
import { CalendarMonth } from "@mui/icons-material";
import TaxCollectorActionCard from "../../components/action-card/link-action-card";
import AlertService from "../../components/alert-service/alert-service";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { getColorByIndex, parseHtml } from "../../utils/utils";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import { Link } from "react-router-dom";
import { isExternalLink } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./index.scss";
import PropTypes from "prop-types";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const currentMonthIndex = new Date().getMonth();

const Home = ({ division }) => {
  const [importantDates, setImportantDates] = useState();
  const [services, setServices] = useState();
  const [linkList, setLinkList] = useState();

  useEffect(() => {
    getImportantDates();
    getServices();
  }, []);

  const getImportantDates = async () => {
    const result = await getDataFromCmsApi("ImportantDate");
    result.map((item) => {
      item.serviceDescription = parseHtml(item.serviceDescription);
      return item;
    });
    setImportantDates(result);
  };

  const getServices = async () => {
    const result = await getDataFromCmsApi(`DivisionService/${division.divisionAbbreviation}`);

    const sortedAndFilteredServices = result
      .filter((service) => !service.isMoreServices)
      .sort((a, b) => a.sortOrder - b.sortOrder);
    setServices(sortedAndFilteredServices);

    const moreServives = result
      .filter((service) => service.isMoreServices && service.taxCollectorMoreServicesCategory)
      .sort(
        (a, b) =>
          a.taxCollectorMoreServicesCategory.localeCompare(b.taxCollectorMoreServicesCategory) ||
          a.sortOrder - b.sortOrder
      )
      .reduce((acc, item) => {
        (acc[item.taxCollectorMoreServicesCategory] = acc[item.taxCollectorMoreServicesCategory] || []).push(
          item
        );
        return acc;
      }, {});
    setLinkList(moreServives);
  };

  const ImportantDates = (monthIndex) => {
    return (
      <>
        {importantDates &&
          importantDates
            .filter((row) => months.indexOf(row.month) === monthIndex)
            .map((row, i) => (
              <ListItem key={i} className="py-0">
                <ListItemAvatar className="mb-3">
                  <Avatar>
                    <CalendarMonth />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${row.month} ${row.day !== 0 ? row.day : ""} `}
                  secondary={parseHtml(row.serviceDescription)}
                />
              </ListItem>
            ))}
      </>
    );
  };

  return (
    <>
      <div className="container">
        {/* Alert */}
        <AlertService divisionCode={division.divisionAbbreviation} />

        {/* Services */}
        <div className="grid-container grid-style-4 py-5">
          {services &&
            services.map((item, index) => (
              <TaxCollectorActionCard
                key={index}
                title={item.serviceLabel}
                linkDestination={item.link}
                cardColor={getColorByIndex(index)}
                target={isExternalLink(item.link) ? "_blank" : ""}
              />
            ))}
        </div>

        {/* More Services */}
        <Box className="py-4">
          <Typography variant="h5" className="pb-3 px-3 px-md-0">
            More Services...
          </Typography>
          {/* <DivisionLinks linkList={linkList} className="testing" /> */}
          <Grid container columnSpacing={12} rowSpacing={6}>
            {linkList &&
              Object.entries(linkList).map(([category, items], index) => (
                <Grid key={index} item sm={12} md={6} lg={4}>
                  <List>
                    <div
                      className="d-flex column-gap-2 justify-content-center align-items-center pb-1 mb-2"
                      style={{ borderBottom: `3px solid #003366`, color: "#003366" }}
                    >
                      {/* <img
                        className="position-absolute start-0"
                        src="/assets/images/nav/sb-county-shield.png"
                        width="28px"
                        height="30px"
                        alt="county-shield"
                      /> */}
                      <span className="fw-bold fs-5">{category}</span>
                    </div>
                    {items.map((link, i) => (
                      <ListItemButton
                        key={i}
                        className="d-flex justify-content-between"
                        divider
                        component={Link}
                        to={link.link}
                        target={isExternalLink(link.link) ? "_blank" : ""}
                        rel={isExternalLink(link.link) ? "noopener noreferrer" : ""}
                      >
                        {link.serviceLabel}
                        <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                      </ListItemButton>
                    ))}
                  </List>
                </Grid>
              ))}
          </Grid>
        </Box>
      </div>

      {/* About */}
      <div className="py-5">
        <DivisionAboutBanner
          divisionAbbreviation={division.divisionAbbreviation}
          divisionTag={division.tag}
          linkTo="/tax-collector/about"
        />
      </div>

      <div className="container">
        {/* Important Dates */}
        <div className="py-5">
          <Typography variant="h5">Upcoming Important Dates</Typography>
          <List>
            {importantDates && ImportantDates(currentMonthIndex)}
            {importantDates && ImportantDates(currentMonthIndex + 1)}
          </List>
          <Button variant="contained" color="primary" href="tax-collector/important-dates">
            View More Dates
          </Button>
        </div>

        {/* Location */}
        <div className="pt-5">
          <Typography variant="h5" className="px-3 mb-3 px-md-0">
            Tax Collector Office Location
          </Typography>
          <div className="d-flex justify-content-center align-content-left">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.1153994877436!2d-117.29152782283867!3d34.06655591697222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcad8248e6ba13%3A0x98750ba7c33ecac6!2sSan%20Bernardino%20County%20ATC!5e0!3m2!1sen!2sus!4v1681325129103!5m2!1sen!2sus"
              width="100%"
              height="390"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              title="ATC Video"
            ></iframe>
          </div>
        </div>

        {/* Contact */}
        <DivisionContact
          divisionAbbreviation={division.divisionAbbreviation}
          sectionTitle={`Contact ${division.title}`}
        />
      </div>
    </>
  );
};

Home.propTypes = {
  division: PropTypes.object.isRequired,
};

export default Home;
