import { Button } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { TbCircleArrowRightFilled } from "react-icons/tb";
import { applyOpacity } from "../../utils/utils";

const LinkActionCard = ({ children, title, linkDestination, cardColor, target }) => {
  return (
    <div className="action-cards-box">
      <Button
        className="action-card"
        component={Link}
        to={linkDestination}
        target={target}
        sx={{
          ":before": {
            background: applyOpacity(cardColor, 0.15),
          },
          ":hover": {
            backgroundColor: cardColor,
          },
        }}
      >
        <div className="hover-color-bubble" style={{ backgroundColor: applyOpacity(cardColor, 0.35) }}></div>
        <div className="action-card-content">
          <h3>
            <b>{title}</b>
          </h3>
          <span style={{ fontSize: "large" }}>{children}</span>
        </div>
        <div className="d-flex justify-content-end w-100">
          <TbCircleArrowRightFilled size={40} />
        </div>
      </Button>
    </div>
  );
};

LinkActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  linkDestination: PropTypes.string.isRequired,
  cardColor: PropTypes.string,
  children: PropTypes.node,
  target: PropTypes.string,
};

export default LinkActionCard;
