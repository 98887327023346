import React from 'react';
import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const WelcomeSection = ({ title, description, children, backgroundImageUrl }) => {

    return (
        <>
            <Box sx={{
                background: `url(${backgroundImageUrl})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                color: "white"
            }} className="welcome-div">
                <div className='welcome-overlay d-flex flex-column justify-content-center text-center align-items-sm-start'>
                    <ThemeProvider theme={theme}>
                        <Typography className="px-3 ps-sm-5" variant="h6">{description || ""}</Typography>
                        <Typography className="px-3 ps-sm-5" variant="h2">{title}</Typography>
                    </ThemeProvider>
                </div>
            </Box>
            <div>
                {children}
            </div>
        </>
    )
}

WelcomeSection.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    children: PropTypes.node,
    backgroundImageUrl: PropTypes.string
};

export default WelcomeSection







