import { useEffect, useState } from "react";
import Carousel from "react-material-ui-carousel";
import { getDataFromCmsApi } from "../../api/contentful-service";

const speed = 10000;

function Alerts() {
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("HomepageBanner");
    setAlerts(result);
  };

  return (
    <div className="alerts-section">
      <Carousel>
        {alerts && alerts.length > 0 && alerts.map((item, i) => (
          <div key={i} interval={speed}>
            <a href={item.link !== "" ? item.link : null} target="_blank" rel="noreferrer" title={item.link}>
              <img className="card-img" src={`https:${item.bannerImageUrl}`} alt={item.title} />
            </a>
          </div>
        ))}
      </Carousel>
    </div>
  );
}
export default Alerts;
