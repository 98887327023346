import React from "react";
import DivisionHome from "../../components/bio/index";
import PropTypes from "prop-types";

function AdminHome({ division }) {
    return (
        <DivisionHome divisionAbbreviation={division.divisionAbbreviation}></DivisionHome>
    );
}

AdminHome.propTypes = {
    division: PropTypes.shape({
        divisionAbbreviation: PropTypes.string.isRequired,
    }).isRequired,
};

export default AdminHome;