import AboutDivision from "../../components/about-divisions/about-division";
import PropTypes from "prop-types";

function InformationTechnologyAbout({ division }) {
  return (
    <AboutDivision
      divisionAbbreviation={division.divisionAbbreviation}
      linkToDivisionHome="/information-technology"
    />
  );
}

InformationTechnologyAbout.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default InformationTechnologyAbout;
