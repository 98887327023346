import {
  USER_PROFILE_LOADED
} from "../_actions/auth-action";

const initialState = {};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case USER_PROFILE_LOADED:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
}
