import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const IndependenceOfTheAuditorFunction = () => {
    const [independenceOfTheAuditorFunction, setIndependenceOfTheAuditorFunction] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("webpage/independenceAuditorFunction");
        setIndependenceOfTheAuditorFunction(result);
    };

    return (
        independenceOfTheAuditorFunction && (
            <StaticPage title={independenceOfTheAuditorFunction.pageTitle}>
                {parseHtml(independenceOfTheAuditorFunction.content)}
            </StaticPage>
        )
    );
};

export default IndependenceOfTheAuditorFunction;