import { Box } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";
import PropTypes from "prop-types";


function Bio({ imgPositionLeft, title, subTitle, imgSrc, description }) {
  const fadeDirection = imgPositionLeft ? "left" : "right";
  const imageOrder = imgPositionLeft ? "order-xl-1 pe-md-2" : "order-xl-2 ps-md-2";
  const descriptionOrder = imgPositionLeft ? "order-xl-2 ps-md-4" : "order-xl-1 pe-md-4";
  const imgWidthSmall = 300;
  const imgWidthLarge = 350;

  return (
    <Fade triggerOnce direction={fadeDirection}>
      <div className="d-flex flex-column flex-md-row align-items-center py-3 py-lg-5">
        <Box
          className={`${imageOrder} py-4`}
          sx={{
            minWidth: { xs: imgWidthSmall, lg: imgWidthLarge },
            maxWidth: { xs: imgWidthSmall, lg: imgWidthLarge },
          }}
        >
          <img src={imgSrc} alt={title} style={{ width: "100%", height: "auto" }} />
        </Box>
        <div className={`py-md-4 ${descriptionOrder}`}>
          <h2>{title}</h2>
          <h5>{subTitle}</h5>
          {description}
        </div>
      </div>
    </Fade>
  );
}

Bio.propTypes = {
  imgPositionLeft: PropTypes.bool,
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  imgSrc: PropTypes.string,
  description: PropTypes.node,
};

export default Bio;
