import React from 'react'
import DefaultLayout from '../../components/layouts/default-layout';
import GSGWidget from '../../components/gsg-widget/gsg-widget';
import { useLocation } from 'react-router';
import { govHubSubPath } from '../../utils/utils.js';

function GovHubServices() {

    // path example:
    // "/tax-services/property-tax/c2FuYmVybmFyZGluby1jYTpnc2d4X3Byb3BlcnR5X3RheDpwYXJlbnRzOmZmZmViOGM0LTJkZDgtMTFlZi05YzdkLTA1NWFkODM1MGJjMQ=="

    const location = useLocation();
    const initialPath = location.pathname.replace('/' + govHubSubPath, '') + location.hash;

    // get title
    const regex = /^([a-zA-Z0-9]{1,20})(-[a-zA-Z0-9]{1,20})*$/; // matches word1-word2-...-wordn
    let title = '';
    initialPath.split('#')[0].split('/').filter(word => word !== "").every(word => { // get the last word that matches the regex
        if (!regex.test(word)) return false; // Stop iterating 
        title = word; 
        return true; 
    });
    title = title.replace(/-/g, ' ').replace(/\b\w/g, char => char.toUpperCase()); // replace '-' with ' ' and capitalize each word

    return (<DefaultLayout title={title} >
        <div>
            <GSGWidget
                initialPath={initialPath}
            />
        </div>
    </DefaultLayout>);
}

export default GovHubServices;
