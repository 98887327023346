import React from "react";
import TreasurerReportsComponent from "../../components/treasurer/treasurer-report-component";

function TreasurerReports() {
    return (
        <div>
            <TreasurerReportsComponent />
        </div>
    );
}

export default TreasurerReports;
