import { RiCustomerServiceFill } from "react-icons/ri";
import { govHubSubPath } from "./utils.js";

export const onlineServiceItemsList = [
    {
        subheader: "Services",
        items: [
            { item: "Pay your Taxes Online", itemLink: "/" + govHubSubPath + "/property-tax" },
            { item: "Pay Your Revenue Recovery Bills", itemLink: "https://www.countybillpay.com/", itemTarget: "_blank" },
            { item: "Online Documents", itemLink: "/search/documents" },
            { item: "Get Electronic Tax Bills", itemLink: "#" },
            { item: "Unclaimed Property Tax Refund", itemLink: "/" + govHubSubPath + "/property-tax" },
            { item: "Report Fraud, Waste, and Abuse page", itemLink: "#" },
            { item: "Customer Satisfaction Survey", itemLink: "/forms/customer-satisfaction" },
        ],
        icon: <RiCustomerServiceFill />,
    },
];
