import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from "redux-saga"
import { composeWithDevTools } from "@redux-devtools/extension";
import reducer from "../_reducers/auth-reducer";
import authSaga from '../_sagas/auth-saga';

const initSagaMiddleware = createSagaMiddleware();

export const authStore = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(initSagaMiddleware)
    )
);

initSagaMiddleware.run(authSaga);

export default authStore;