import React from "react";
import ContentSection from "../content-section";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { getDataFromCmsApi } from "../../api/contentful-service";
import PropTypes from "prop-types";

const NewsPublicationsSection = ({ category, itemList }) => {
  return (
    <div className="d-flex flex-column justify-content-between col-12 col-xl-6 p-3 px-lg-5">
      <div>
        <h3>{category === "News" ? "In Other News..." : "Newsletters"}</h3>
        <div className="list-group list-group-flush">
          {itemList
            .map((item, index) => <NewsPublicationsItem key={index} item={item}></NewsPublicationsItem>)
            .slice(0, 6)}
        </div>
      </div>
      <div className="py-3 text-end">
        <Button component={Link} to={`/${category.toLowerCase()}`}>
          Go to {category}
        </Button>
      </div>
    </div>
  );
};

const NewsPublicationsItem = ({ item }) => {
  return (
    <div className="list-group-item flex-column align-items-start pe-0 pe-sm-3">
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex w-100">
          <div style={{ minWidth: "95px" }}>{new Date(item.publishedDate).toLocaleDateString()}</div>
          {item.title}
        </div>
        <Button
          aria-label={`Read More ${item.title}`}
          component={Link}
          target="_blank"
          to={`https:${item.documentUrl}`}
        >
          <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
        </Button>
      </div>
    </div>
  );
};

function NewsAndPublications() {
  const [news, setNews] = useState([]);
  const [publications, setPublications] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("NewsAndPublications");

    const allNews = result.filter((item) => {
      return item.category === "News";
    });

    const allPublications = result.filter((item) => {
      return item.category === "Newsletter";
    });

    setNews(allNews);
    setPublications(allPublications);
  };

  return (
    <div
      className="news-publication position-relative pt-xl-5 pt-0 pb-5"
      style={{ marginBottom: "-48px" }}
    >
      <div className="custom-shape-divider-top-1679438911">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
        </svg>
      </div>
      <ContentSection header="News and Newsletters">
        <div className="container d-flex justify-content-center align-content-center mt-md-4">
          <div className="row d-block d-xl-flex">
            <NewsPublicationsSection category="News" itemList={news} />
            <NewsPublicationsSection category="Newsletters" itemList={publications} />
          </div>
        </div>
      </ContentSection>
      <div className="custom-shape-divider-bottom-1679439189" style={{ marginBottom: "48px" }}>
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M1200 0L0 0 892.25 114.72 1200 0z" className="shape-fill"></path>
        </svg>
      </div>
    </div>
  );
}

NewsPublicationsSection.propTypes = {
  category: PropTypes.string,
  itemList: PropTypes.array,
};

NewsPublicationsItem.propTypes = {
  item: PropTypes.object,
};

export default NewsAndPublications;
