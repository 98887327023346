import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function TreasurerReportsComponent() {
    const [rows, setRows] = useState([]);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 10,
        page: 0,
    });
    const columns = [
        { field: "Report Category", headerName: "Report Category", width: 500, flex: 1, },
        { field: "Fiscal year", headerName: "Fiscal year", width: 500, flex: 1, },
        { field: "Report Name", headerName: "Report Name", width: 500, flex: 1, },

    ];
    const fetchReports = async () => {
        await axios
            .get("../data/treasurer-reports.json")
            .then((res) =>
                setRows(res.data)
            );

    };

    useEffect(() => {
        fetchReports();
    }, []);

    return (
        <Box className="px-3" alignItems="center" sx={{background:"white"} } >
            <br />
            <br />
            <Grid container spacing={1}>
                <Grid item xs={0} lg={2}>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <h1>Treasurer Publications and Reports</h1>
                    <p>Hello and welcome to our reports viewer page! We are excited to share with you the latest insights and trends in the industry. Our team has worked hard to put together a comprehensive report that we hope you will find informative and engaging.</p>
                </Grid>
                <Grid item xs={0} lg={2}>
                </Grid>
            </Grid>
            <br />
            <br />
            <Grid container spacing={1}>
                <Grid item xs={0}  lg={2}>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        pageSizeOptions={[5, 10, 25, 100]}
                    />
                </Grid>
                <Grid item xs={0}  lg={2}>
                </Grid>
            </Grid>
        </Box>
    );
}