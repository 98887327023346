import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';

const GAContext = createContext(false);

export const GAContextProvider = ({ children }) => {
    // Initialize consentGiven based on the GATracking cookie
    const [consentGiven, setConsentGiven] = useState(() => {
        return Cookies.get('GATrackingConsent') === 'true';
    });

    // Function to update consent and set the GATracking cookie
    const updateConsentGiven = (value) => {
        setConsentGiven(value);
        if (value) {
            Cookies.set('GATrackingConsent', 'true', { expires: 365 });
        } else {
            Cookies.set('GATrackingConsent', 'false', { expires: 365 });
        }

        // Enable or disable Google Analytics based on consent
        window[`ga-disable-${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`] = !value;
    };

    return (
        <GAContext.Provider value={{ consentGiven, updateConsentGiven }}>
            {children}
        </GAContext.Provider>
    );
};

GAContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useGAContext = () => useContext(GAContext);