import React, { useEffect, useState } from "react";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";

export default function RFI() {
  const [rfiPage, setRfiPage] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/brokersDealersRfi");
    setRfiPage(result);
  };

  return (
    rfiPage && (
      <StaticPageContainer title={rfiPage.pageTitle}>{parseHtml(rfiPage.content)}</StaticPageContainer>
    )
  );
}
