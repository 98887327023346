import React from "react";
import { signOut } from "../../services/auth";
import { Avatar, Button, MenuItem, Divider, Menu, ListItemIcon } from "@mui/material";
import { Logout, Settings, Dashboard } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
//import { getWidgetConfig } from "../../utils/gsg-dynamic-config";
import { govHubSubPath } from '../../utils/utils.js';
import PropTypes from 'prop-types';

function ProfileMenu({ anchorEl, open, handleClose, storedUser }) {
  const navigate = useNavigate();

  const menuItems = [
    { text: "Settings", icon: <Settings fontSize="small" />, onClick: iframeUpdateSrc },
    { text: "Logout", icon: <Logout fontSize="small" />, onClick: signOut },
  ];

  const iFrameUrl = process.env.REACT_APP_ROOTIFRAMEURL + "/ca-sanbernardino/ca-sanbernardino/my-dashboard";
  const rootIframeId = "gsg-public-site";
  function iframeUpdateSrc() {
    if (document.getElementById(rootIframeId))
      document.getElementById(rootIframeId).src = iFrameUrl + "#my-settings";
    else
      navigate("/" + govHubSubPath + "/my-dashboard#my-settings");
  }

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            borderRadius: 2,
            mt: 0.5,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center px-5 pb-4">
        <Avatar src={storedUser.picture} sx={{ width: 80, height: 80, m: 3 }} />
        <h5>
          Hi, <b>{storedUser.name}</b>!
        </h5>
        <p>{storedUser.email}</p>
        <Button
          type="button"
          variant="outlined"
          fullWidth
          startIcon={<Dashboard />}
          style={{ borderRadius: 50, margin: "5px 0" }}
          component={Link}
          //to="/profile"
          onClick={(e) => {
            e.preventDefault();
            if (document.getElementById(rootIframeId))
              document.getElementById(rootIframeId).src = iFrameUrl + "#my-items";
            else
              //const { route, widget } = getWidgetConfig('profile');
              //console.log("Manage Profile: ", route, widget)
              //return navigate(route, { state: { config: widget } });
              return navigate("/" + govHubSubPath + "/my-dashboard#my-items");
          }}
        >
          My Dashboard
        </Button>
      </div>

      <Divider />

      <div className="py-2">
        {menuItems.map((item, i) => (
          <MenuItem key={i} onClick={item.onClick}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            {item.text}
          </MenuItem>
        ))}
      </div>
    </Menu>
  );
}

ProfileMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  storedUser: PropTypes.object
};

export default ProfileMenu;