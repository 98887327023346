import { useEffect, useState } from "react";
import StaticPageContainer from "../../components/static-page";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";

const SupplementalTaxBillInfo = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/supplementalTaxBills");
    setData(result);
  };

  return data && <StaticPageContainer title={data.pageTitle}>{parseHtml(data.content)}</StaticPageContainer>;
};

export default SupplementalTaxBillInfo;
