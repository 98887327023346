import React, { useState } from "react";
import PropTypes from "prop-types";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const CustomPDFRenderer = ({ mainState }) => {
  const { currentDocument } = mainState;
  const [numPages, setNumPages] = useState(null);

  if (!currentDocument) return null;
  
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div id="my-pdf-renderer">
      <Document
        file={currentDocument.uri}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.error("Error loading document:", error)}
        onSourceError={(error) => console.error("Error with document source:", error)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </div>
  );
};

CustomPDFRenderer.fileTypes = ["pdf", "application/pdf"];
CustomPDFRenderer.weight = 1;

CustomPDFRenderer.propTypes = {
  mainState: PropTypes.object.isRequired,
};

export default CustomPDFRenderer;
