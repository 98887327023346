import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const ReportFraudWasteAbuse = () => {
    const [reportFraudWasteAbuse, setReportFraudWasteAbuse] = useState();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("webpage/reportFraudWasteAbuse");
        setReportFraudWasteAbuse(result);
    };

    return (
        reportFraudWasteAbuse && (
            <StaticPage title={reportFraudWasteAbuse.pageTitle}>
                {parseHtml(reportFraudWasteAbuse.content)}
            </StaticPage>
        )
    );
};

export default ReportFraudWasteAbuse;