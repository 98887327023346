import ReactHtmlParser from "html-react-parser";
import DOMPurify from "dompurify";
import he from "he";

// export const cardColors = ["#0a294d", "#3b8c9e", "#cd9e0c", "#194d79", "#518466", "#1c6889", "#b47f29", "#2d615f"];
// export const cardColors = ["#0a294d","#3b8c9e","#cd9e0c","#194d79","#518466","#1c6889","#b47f29","#2d615f","#B5552C"];
// export const cardColors = ["#0a294d","#cd9e0c","#3b8c9e","#518466","#1b5b81","#c48621","#2d615f","#B5552C"];
export const cardColors = ["#B5552C", "#1C6889", "#C98E2E", "#518466", "#0a294d"];

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const titleFontSize = "calc(20px + 0.6vw)";
export const subtitleFontSize = "calc(19px + 0.5vw)";
export const textFontSize = "calc(15px + 0.3vw)";

export const govHubSubPath = "tax-services";

export const parseHtml = (html) => {
  if (!html || typeof html !== "string") return null;

  const decodedHighlightValue = he.decode(html);
  const sanitizedHighlightValue = DOMPurify.sanitize(decodedHighlightValue.replaceAll(`\n`, "<br>"));
  return ReactHtmlParser(sanitizedHighlightValue);
};

export const isExternalLink = (link) => {
  // if the link starts with a / and a letter OR is "#" -> not external link
  if (!link) return false;
  return !link.match(/^\/[a-zA-Z]|#$/);
};

export const getColorByIndex = (index) => {
  const colorIndex = index % cardColors.length;
  return cardColors[colorIndex];
};

export const applyOpacity = (color, opacity) => {
  const canvas = document.createElement("canvas");
  canvas.width = canvas.height = 1;
  const ctx = canvas.getContext("2d");

  // Set the fill style to the color and apply it to the canvas
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, 1, 1);

  // Get the RGB value of the color
  const [r, g, b] = ctx.getImageData(0, 0, 1, 1).data;

  // Return the color in RGBA format with the specified opacity
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const getRandomColors = (numColors, name) => {
  if (numColors === 0) return [];

  // Simple hash function to generate a seed based on a string
  const hashStringToSeed = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
  };

  // Seeded random function using a seed
  const seededRandom = (seed) => {
    let value = seed % 2147483647;
    return () => {
      value = (value * 16807) % 2147483647;
      return (value - 1) / 2147483646;
    };
  };

  const seed = hashStringToSeed(name);
  const random = seededRandom(seed);

  // Shuffle colors using seeded random function
  const shuffledColors = [...cardColors].sort(() => random() - 0.5);
  const colors = [];

  // Ensure we have enough colors to fill numColors
  while (colors.length < numColors) {
    colors.push(...shuffledColors);
  }

  return colors.slice(0, numColors);
};
