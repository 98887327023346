import { PiLinkSimpleBold } from "react-icons/pi";
import { BiSolidHelpCircle } from "react-icons/bi";

export const generalInfoItemsList = [
  {
    subheader: "Useful Links",
    items: [
        { item: "San Bernardino County Website", itemLink: "https://main.sbcounty.gov/",itemTarget:"_blank" },
        { item: "Assessor-Recorder-County Clerk", itemLink: "https://arc.sbcounty.gov/", itemTarget: "_blank" },
        { item: "District Attorney", itemLink: "https://da.sbcounty.gov/", itemTarget: "_blank" },
        { item: "Sheriff", itemLink: "https://wp.sbcounty.gov/sheriff/", itemTarget: "_blank" },
        { item: "Board of Supervisors", itemLink: "https://main.sbcounty.gov/", itemTarget: "_blank" },
    ],
    icon: <PiLinkSimpleBold />,
  },
  {
    subheader: "Help & Contact",
    items: [
      { item: "Frequently Asked Questions", itemLink: "/faq" },
      { item: "Hours, Locations, and Contact Numbers", itemLink: "/hours-and-locations" },
      { item: "Contact Us", itemLink: "/contact-us" },
    ],
    icon: <BiSolidHelpCircle />,
  },
];
