import { AddBusiness, Home, Info, MonetizationOn, OnlinePrediction, Roofing, Help } from "@mui/icons-material";

export const navbarList = [
  {
    subheader: "Home",
    subheaderLink: "/",
    icon: <Home />,
  },
  {
    subheader: "Online Services",
    icon: <OnlinePrediction />,
  },
  {
    subheader: "Tax Collector",
    subheaderLink: "/tax-collector",
    icon: <MonetizationOn />,
  },
  {
    subheader: "Property Tax",
    subheaderLink: "/property-tax",
    icon: <AddBusiness />,
  },
  {
    subheader: "Divisions",
    icon: <Roofing />,
  },
  {
    subheader: "General Information",
    icon: <Help />,
  },
  {
    subheader: "About",
    subheaderLink: "/about",
    icon: <Info />,
  },
];
