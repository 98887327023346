import AboutDivision from "../../components/about-divisions/about-division";
import PropTypes from "prop-types";

function DisbursementsAbout({ division }) {
  return (
    <AboutDivision divisionAbbreviation={division.divisionAbbreviation} linkToDivisionHome="/disbursements" />
  );
}

DisbursementsAbout.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default DisbursementsAbout;
