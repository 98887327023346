import React, { useEffect, useState } from "react";
import { Pagination, Box, Button, Card, CardMedia, Typography } from "@mui/material";
import { format } from "date-fns";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { CalendarMonth } from "@mui/icons-material";
import './news-and-publications.scss';
import PropTypes from "prop-types";

NewsPublicationsPageDetails.propTypes = {
  page: PropTypes.string.isRequired,
};

function NewsPublicationsPageDetails({ page }) {
  const [news, setNews] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("NewsAndPublications");

    const allNews = result.filter((item) => {
      return item.category === page;
    });

    setNews(allNews);
  };

  const [paginationPage, setPage] = useState(1);
  const itemsPerPage = 10;

  const handleChange = (event, value) => {
    setPage(value);
  };
  const paginatedItems = news.slice((paginationPage - 1) * itemsPerPage, paginationPage * itemsPerPage);
  const displayNews = paginatedItems.map((item, index) => (
    <Card
      key={index}
      variant="outlined"
      sx={{
        boxShadow: "none",
        display: "flex",
        alignItems: "center",
        flexDirection: {
          xs: "column", // mobile
          md: "row", // tablet and up
        },
        width: "100%",
        m: 3,
      }}
    >
      <CardMedia
        component="img"
        width="200"
        height="200"
        alt="123 Main St, Phoenix, AZ cover"
        src={item.titleImageUrl}
        sx={{
          backgroundColor: "#003366",
          borderRadius: 0.5,
          width: { xs: "100%", md: 200 },
          objectFit: "cover",
          m: { xs: 0, md: 3 },
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignSelf: "center",
          m: 2,
        }}
      >
        <div className="d-flex align-items-center">
          <CalendarMonth fontSize="small" sx={{ color: "#666666", marginRight: 1 }} />
          <Typography className="row" variant="body2" color="text.secondary" fontWeight="medium">
            Published on {format(item.publishedDate, "EEEE, MMMM dd, yyyy")}
          </Typography>
        </div>
        <Typography variant="h6" fontWeight="bold">
          {item.title}
        </Typography>
        <Typography variant="body1" color="text.secondary" fontWeight="medium">
          {item.description}
        </Typography>
        <Button
          size="small"
          variant="outlined"
          href={`https:${item.documentUrl}`}
          target="_blank"
          sx={{ alignSelf: "flex-start" }}
        >
          View Article
        </Button>
      </Box>
    </Card>
  ))

  return (
    <Card
      className="container"
      variant="outlined"
      sx={{
        border: "none",
        padding: { xs: "10vw", lg: "8vh 15vw" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1 className="pb-4">Latest {page === "Newsletter" ? "Newsletters" : "News"}</h1>

      {displayNews}
      <br />
      <Pagination
        count={Math.ceil(news.length / itemsPerPage)}
        page={paginationPage}
        onChange={handleChange}
        color="primary"
      />
    </Card>

  );
}

export default NewsPublicationsPageDetails;
