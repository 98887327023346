import React, { useEffect } from "react";
import { connect } from "react-redux";
import { handleAuthenticationCallback } from "../_actions/auth-action";
import { useNavigate } from 'react-router-dom';
import { govHubSubPath } from '../utils/utils.js';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

let Callback = ({ dispatch, user, }) => {
  const navigate = useNavigate();

  document.title = "Callback";
  useEffect(() => {
    console.log("Callback user: ", user);
    console.log("Callback dispatch: ", dispatch);
    if (user) {
      //const { route, widget } = getWidgetConfig('login');
      //// TODO: redirect to user profile page
      //console.log("Callback config: ", route, widget);
      //return navigate(route, { state: { config: widget } });
      return navigate("/" + govHubSubPath + "/my-dashboard");
    };

    // distpatch action to handle authentication
    dispatch(handleAuthenticationCallback());
  }, [user]);

  return <div className="text-center">Loading user profile...</div>;
};

Callback = connect(mapStateToProps)(Callback);

Callback.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object
};

export default Callback;
