import { useEffect, useState } from "react";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { parseHtml } from "../../utils/utils";
import StaticPage from "../../components/static-page";

const UnderstandingPostmarks = () => {
  const [understandingPostmarks, setUnderstandingPostmarks] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const result = await getDataFromCmsApi("webpage/understandingPostmarks");
    setUnderstandingPostmarks(result);
  };

  return (
    understandingPostmarks && (
      <StaticPage title={understandingPostmarks.pageTitle}>
        {parseHtml(understandingPostmarks.content)}
      </StaticPage>
    )
  );
};

export default UnderstandingPostmarks;
