import AboutDivision from "../../components/about-divisions/about-division";
import PropTypes from "prop-types";

function TreasurerAbout({ division }) {
  return (
    <AboutDivision divisionAbbreviation={division.divisionAbbreviation} linkToDivisionHome="/treasurer" />
  );
}

TreasurerAbout.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default TreasurerAbout;
