import React, { useEffect, useRef } from "react";
import { applyOpacity, parseHtml } from "../../utils/utils";
import PropTypes from "prop-types";
import { Card, CardContent, CardHeader } from "@mui/material";
import { AccessTime, Business, Call } from "@mui/icons-material";

const DivisionGroupContact = ({ divisionGroupContacts }) => {
  const divRefs = useRef([]);

  useEffect(() => {
    const maxHeight = Math.max(...divRefs.current.map((ref) => ref.getBoundingClientRect().height));
    divRefs.current.forEach((ref) => {
      ref.style.height = `${maxHeight}px`;
    });
  }, [divisionGroupContacts]);

  return (
    <div className="row my-4 d-flex justify-content-center row-gap-4">
      {divisionGroupContacts &&
        divisionGroupContacts.map((item, i) => (
          <div key={i} className="col-12 col-md-6 col-xl-4 px-3">
            <Card
              className="w-100 h-100 division-hover-bubble-container"
              sx={{
                color: "#435568",
                boxShadow: "0px 2px 8px 0px rgba(0,0,0,0.1)",
                "&::before": {
                  right: "-260px",
                  bottom: "-450px",
                  backgroundColor: applyOpacity(item.backgroundColor, 0.2),
                },
              }}
            >
              <CardHeader
                ref={(el) => (divRefs.current[i] = el)}
                title={
                  <h3
                    className="my-2 fw-bold text-center"
                    style={{ fontSize: "min(calc(22px + 0.3vw), 30px)" }}
                  >
                    {item.divisionGroupLabel}
                  </h3>
                }
              />

              <CardContent
                className="service-content"
                sx={{ padding: { xs: "20px 24px 10px", xl: "20px 35px 10px" } }}
              >
                <div
                  className="division-hover-bubble d-none d-xl-block"
                  style={{
                    backgroundColor: applyOpacity(item.backgroundColor, 0.2),
                    top: "35rem",
                    left: "-20rem",
                  }}
                />

                <div className="pb-3 d-flex">
                  <Business className="me-3" />
                  <div className="w-100">
                    <h5 className="fw-bold">Address:</h5>
                    <p>
                      {item.streetAddress} <br />
                      {`${item.city}, ${item.state} ${item.zipCode}`} <br />
                    </p>
                  </div>
                </div>

                <div className="pb-3 d-flex">
                  <AccessTime className="me-3" />
                  <div className="w-100">
                    <h5 className="fw-bold">Business Hours:</h5>
                    {parseHtml(item.operatingHours)}
                  </div>
                </div>

                <div className="pb-3 d-flex">
                  <Call className="me-3" />
                  <div className="w-100">
                    <h5 className="fw-bold">Phone:</h5>
                    <p>
                      {item.phone1} <br />
                      {item.phone2} <br />
                      {item.phone3} <br />
                      {item.phone4} <br />
                      {item.phone5}
                    </p>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
    </div>
  );
};

DivisionGroupContact.propTypes = {
  divisionGroupContacts: PropTypes.array,
};

export default DivisionGroupContact;
