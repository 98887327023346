import AboutDivision from "../../components/about-divisions/about-division";
import PropTypes from "prop-types";

function SAPAbout({ division }) {
  return <AboutDivision divisionAbbreviation={division.divisionAbbreviation} linkToDivisionHome="/sap" />;
}

SAPAbout.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default SAPAbout;
