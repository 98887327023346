import AboutDivision from "../../components/about-divisions/about-division";
import PropTypes from "prop-types";

function AuditorAbout({ division }) {
  return <AboutDivision divisionAbbreviation={division.divisionAbbreviation} linkToDivisionHome="/internal-audits" />;
}

AuditorAbout.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
  }).isRequired,
};

export default AuditorAbout;
