// scroll to # on main homepage only
const scrollToTop = () => {
    const hash = window.location.hash;
    const hashLinks = ["#", "#services", "#meetATC", "#accomplishments", "#achievements", "#news"];

    if (hashLinks.includes(hash)) {
        if (document.querySelector(hash)) {
            window.location.replace(window.location.hash); // scroll to hash link (even when link is pasted to a new tab or clicked from an outside source)
        } else {
            window.scrollTo(0, 0); // scroll to top of target page if hash link does not exist
        }
    } else {
        window.scrollTo(0, 0); // scroll to top of target page if link is not a hash link
    }
}

export default scrollToTop;