import React from "react";
import NewsPublicationsPageDetails from "../../components/news-publications/news-publications-page-details";

function NewsAndPublications() {
    React.useEffect(() => {
        ["scroll", "load"].forEach(function (e) {
            //  window.addEventListener(e, mouseMoveHandler, false);
            window.addEventListener(e, function () {
                var links = document.getElementsByClassName("dot");
                var top = window.scrollY;
                document.querySelectorAll(".sec").forEach(function (value) {
                    var id = value.getAttribute("id");
                    var height = value.scrollHeight;
                    var offset = value.offsetTop - 150;
                    let i = 0;
                    while (i < links.length) {
                        var link = links[i];
                        if (link.getAttribute("data-scroll").toUpperCase() === id.toUpperCase()) {
                            //link.classList.remove('active');
                            break;
                        }
                        i++;
                    }
                    if (top >= offset && top < offset + height) {
                        document
                            .querySelector(".ScrollSpyMenu")
                            .querySelector('[data-scroll="' + id + '"]')
                            .classList.add("active");
                    }
                });
            });
        });
    });

    return (
        <div className="overflow-hidden">
            <title>Welcome to the San Bernardino County Auditor-Controller/Treasurer/Tax Collector</title>
            <meta
                name="description"
                content="This office is committed to serving our customers by processing, safeguarding, and providing information regarding the finances and public records of the County. We perform these functions with integrity, independent judgment, and outstanding service. We are accurate, timely, courteous, innovative, and efficient because of our well-trained and accountable staff. "
                key="desc"
            />
            <nav className="ScrollSpyMenu d-none d-md-block">
                <ul>
                    <li>
                        <a href="news#" className="dot active" data-scroll="news">
                            <span>News</span>
                        </a>
                    </li>
                </ul>

                <ul>
                    <li>
                        <a href="newsletters" className="dot" data-scroll="publications">
                            <span>Newsletters</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <div className="sec" id="news">
                <NewsPublicationsPageDetails page="News" />
            </div>
        </div>
    );
}
export default NewsAndPublications;
