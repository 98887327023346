import AlertService from "../../components/alert-service/alert-service";
import DivisionServices from "../../components/division-homepage/division-services";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionContact from "../../components/division-homepage/division-contact";
import PropTypes from "prop-types";
import LinkActionCard from "../../components/action-card/link-action-card";
import { getColorByIndex, isExternalLink } from "../../utils/utils";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";

function ControllerHome({ division }) {
  const [services, setServices] = useState();

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    const result = await getDataFromCmsApi(`DivisionService/${division.divisionAbbreviation}`);

    const sortedAndFilteredServices = result.sort((a, b) => a.sortOrder - b.sortOrder);
    setServices(sortedAndFilteredServices);
  };

  return (
    <>
      <div className="container">
        <AlertService divisionCode={division.divisionAbbreviation} />

        <Typography variant="h2" className="text-center">
          Our Services
        </Typography>
        <div className="grid-container grid-style-4 py-5">
          {services &&
            services.map((item, index) => (
              <LinkActionCard
                key={index}
                title={item.serviceLabel}
                linkDestination={item.link}
                cardColor={getColorByIndex(index)}
                target={isExternalLink(item.link) ? "_blank" : ""}
              />
            ))}
        </div>
      </div>
      <div className="pt-5">
        {/* <DivisionServices divisionAbbreviation={division.divisionAbbreviation} sectionTitle="Our Services" /> */}

        <DivisionAboutBanner
          divisionAbbreviation={division.divisionAbbreviation}
          divisionTag={division.tag}
          linkTo="/controller/about"
        />
        <DivisionContact
          divisionAbbreviation={division.divisionAbbreviation}
          sectionTitle={"Contact Controller"}
        />
      </div>
    </>
  );
}

ControllerHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default ControllerHome;
