// Utility function to return widget config based on action
function getWidgetConfig(action) {
    console.log(">> getWidgetConfig: ", action);
    const config = { route: "", widget: { initialPath: '/' } };

    // Determine route and widget configuration based on action
    switch (action) {
        case 'pay-online':
            config.route = '/tax-collector/pay-online';
            config.widget = { initialPath: '/property-tax' };
            break;
        case 'bulk-payments':
        case 'login':
        case 'profile':
        case 'view-payment-history':
        case 'view-bill':
            config.route = '/profile';
            config.widget = { initialPath: `/my-dashboard${action === 'view-payment-history' ? '#my-payments' : (action === 'view-bill' ? '#my-items' : '')}` };
            break;
        case 'direct-charges':
            config.route = '/profile';
            config.widget = { initialPath: `/my-dashboard` }; //to be changed to something direct-charges when available
            break;
        case 'checkout':
            config.route = '/profile';
            config.widget = { initialPath: '/checkout' };
            break;
        default:
            config.route = '/property-information';
            config.widget = { initialPath: '/property-tax' };
    }

    // Return widget configuration
    return config;
}

export { getWidgetConfig };
