import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { AccessTime, Business, Call, Email, Info } from "@mui/icons-material";
import PropTypes from "prop-types";
import { parseHtml } from "../../utils/utils";
import "./division-contact.scss";

const iconSize = "50px";

function DivisionContact({ divisionAbbreviation, sectionTitle, formLink }) {
    const [divisionContact, setDivisionContact] = useState([]);

    useEffect(() => {

        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi(`DivisionContact/${divisionAbbreviation}`);
        const filteredData = result.filter(item => !item.isGroup);
        setDivisionContact(filteredData[0]);
    };

    const ContactItem = ({ children, icon, title }) => {
        return (
            <Box
                className="d-flex flex-column align-items-center text-center col-12 col-sm-6 col-lg-4 col-xl-3 py-3 px-4 m-2"
                sx={{
                    borderRadius: 3,
                    // background: "linear-gradient(0deg, hsla(210, 100%, 96%, 0.4), hsla(215, 15%, 97%, 0.1))",
                    // border: `1px solid #e8eaee`,
                    backgroundColor: "#f7fbff",
                    color: "#003366",
                }}
            >
                <div className="m-2">{icon}</div>
                <b className="m-2" style={{ fontSize: "19px" }}>{title}</b>
                <div className="mb-2">{children}</div>
            </Box>
        );
    };

    ContactItem.propTypes = {
        children: PropTypes.node.isRequired,
        icon: PropTypes.node.isRequired,
        title: PropTypes.string.isRequired,
    };

    return (
        <div className="container px-3 px-lg-5 py-5 my-5">
            <h1 className="display-4 text-center py-3">{sectionTitle}</h1>

            <div className="row justify-content-center mb-2 mx-3">
                <ContactItem icon={<Business style={{ fontSize: iconSize }} />} title="Address">
                    {divisionContact && (
                        <>
                            {divisionContact.streetAddress} <br />
                            {divisionContact.city}, {divisionContact.state} {divisionContact.zipCode}
                        </>
                    )}
                </ContactItem>

                <ContactItem icon={<Call style={{ fontSize: iconSize }} />} title="Phone">
                    {divisionContact && (
                        <>
                            <div>{divisionContact.phone1 && <div>{divisionContact.phone1}</div>}</div>
                            <div>{divisionContact.phone2 && <div>{divisionContact.phone2}</div>}</div>
                            <div>{divisionContact.phone3 && <div>{divisionContact.phone3}</div>}</div>
                            <div>{divisionContact.phone4 && <div>{divisionContact.phone4}</div>}</div>
                            <div>{divisionContact.phone5 && <div>{divisionContact.phone5}</div>}</div>

                        </>
                    )}
                </ContactItem>
                {divisionContact && divisionContact.emailAddress && (
                    <ContactItem icon={<Email style={{ fontSize: iconSize }} />} title="Email">
                        {divisionContact.emailAddress}
                    </ContactItem>
                )}
                {divisionContact && divisionContact.operatingHours && (
                    <ContactItem icon={<AccessTime style={{ fontSize: iconSize }} />} title="Operating Hours">
                        <div className="operating-hours">
                            {parseHtml(divisionContact.operatingHours)}
                        </div>
                    </ContactItem>
                )}
                {/* {console.log(divisionContact)} */}
            </div>
            {formLink && (
                <div className="d-flex justify-content-center pt-2 pb-5">
                    <Button variant="contained" component={Link} to="/">
                        <Info className="me-2" />
                        Contact {divisionAbbreviation} form
                    </Button>
                </div>
            )}
        </div>
    );

}

DivisionContact.propTypes = {
    divisionAbbreviation: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string.isRequired,
    formLink: PropTypes.string,
};



export default DivisionContact;
