import { all, call, put } from "redux-saga/effects";
import { takeLatest } from "redux-saga/effects";
import { HANDLE_AUTHENTICATION_CALLBACK, USER_PROFILE_LOADED } from "../_actions/auth-action";
import { handleAuthentication } from "../services/auth";

export function* parseHash() {
  const user = yield call(handleAuthentication);
  yield put({ type: USER_PROFILE_LOADED, user });
}

export function* handleAuthenticationCallback() {
  yield takeLatest(HANDLE_AUTHENTICATION_CALLBACK, parseHash);
}

// Watcher
export default function* authSaga() {
  yield all([handleAuthenticationCallback()]);
}
