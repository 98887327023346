import { useEffect, useState } from "react";
import JotformEmbed from "react-jotform-embed";
import { getDataFromCmsApi } from "../api/contentful-service";
import { useParams } from "react-router";
import DefaultLayout from "./layouts/default-layout";
import Loader from "./loader";
import PropTypes from "prop-types";

function JotFormContainer({ pageTitle }) {
  const [isLoading, setIsLoading] = useState(true);
  const [form, setForm] = useState([]);
  const { formIdentifier } = useParams();

  useEffect(() => {
    getFormData().then(() => setIsLoading(false));
  }, [formIdentifier]);

  const getFormData = async () => {
    const result = await getDataFromCmsApi(`Form/${formIdentifier}`);
    setForm(result[0]);
  };

  return (
    <DefaultLayout title={pageTitle} description="Welcome to San Bernardino's">
      {isLoading ? <Loader /> : <JotformEmbed src={`https://form.jotform.com/${form.jotFormID}`} />}
    </DefaultLayout>
  );
}

JotFormContainer.propTypes = {
  pageTitle: PropTypes.string.isRequired,
};

export default JotFormContainer;
