import { Box, Collapse, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const CustomFilterColumn = ({ children, filterColumnName }) => {
  return (
    <div className="pb-2">
      <Typography variant="overline" component="h1" gutterBottom>
        {filterColumnName}
      </Typography>
      {children}
    </div>
  );
};

const CustomFilterWidget = ({ isFilterOpen, filterColumns, backgroundColor }) => {
  return (
    <Collapse
      in={isFilterOpen}
      sx={{
        backgroundColor: backgroundColor,
        marginTop: 1,
        marginBottom: 1,
        "& .MuiCollapse-wrapperInner": {
          display: { xs: "flex", sm: "block" },
          justifyContent: "center",
        },
      }}
    >
      <Box className="d-grid d-sm-flex flex-wrap column-gap-5 row-gap-4 p-3">
        {filterColumns &&
          filterColumns.map((column, index) => (
            <CustomFilterColumn key={index} filterColumnName={column.filterColumnName}>
              {column.refinementListComponent}
            </CustomFilterColumn>
          ))}
      </Box>
    </Collapse>
  );
};

CustomFilterWidget.propTypes = {
  isFilterOpen: PropTypes.bool.isRequired,
  filterColumns: PropTypes.array.isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

CustomFilterColumn.propTypes = {
  children: PropTypes.node.isRequired,
  filterColumnName: PropTypes.string.isRequired,
};

export default CustomFilterWidget;
